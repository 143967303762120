<template>
  <!-- 资金管理--财务管理 -->
  <div class="financial-container">
    <div class="eacharbox-container">
      <div class="leftecharBox-container">
        <div class="echar-title">
          财务收支状态
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div id="myChart"></div>
      </div>
      <div class="rightecharBox-container">
        <div class="echar-title">
          订单状态
          <el-select v-model="value2" placeholder="请选择" class="optionfirst">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="value3" placeholder="请选择">
            <el-option
              v-for="item in option2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="orderEchartBox">
          <div id="orderEchart" class=""></div>
          <div class="orderEchart-progress-box">
            <div class="progress">
              <p>66<span>单</span></p>
              <el-progress
                :percentage="66"
                :show-text="false"
                class="done"
              ></el-progress>
            </div>
            <div class="progress-tittle">
              <span>已完成订单</span>
              <el-button>查看</el-button>
            </div>
          </div>
          <div class="orderEchart-progress-box">
            <div class="progress">
              <p>51<span>单</span></p>
              <el-progress
                :percentage="51"
                :show-text="false"
                class="doing"
              ></el-progress>
            </div>
            <div class="progress-tittle">
              <span>进行中</span>
              <el-button>查看</el-button>
            </div>
          </div>
          <div class="orderEchart-progress-box">
            <div class="progress">
              <p>16<span>单</span></p>
              <el-progress
                :percentage="16"
                :show-text="false"
                class="undone"
              ></el-progress>
            </div>
            <div class="progress-tittle">
              <span>退款订单</span>
              <el-button>查看</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="finanacial-btncontainer">
      <el-button class="allbtn">全部</el-button>
      <el-select
        v-model="value"
        placeholder="请选择"
        class="selectbtn selectbtnclass"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="value"
        placeholder="请选择"
        class="selectbtn selectmountclass"
      >
        <el-option
          v-for="item in option2"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="search"
        placeholder="请输入内容"
        class="searchinput"
        suffix-icon="el-icon-search"
      ></el-input>
    </div>
    <div class="table-container">
      <financialmanagementtable-com></financialmanagementtable-com>
    </div>
    <pagination-com></pagination-com>
  </div>
</template>


<script>
import * as echarts from 'echarts'
import financialmanagementtableCom from '../../components/financialmanagement/financialmanagementtable.vue'
import paginationCom from '../../components/paginationCom.vue'


export default {
  components: {
    financialmanagementtableCom, paginationCom
  },
  data() {
    return {
      options: [{
        value: '0',
        label: '青秀区'
      }, {
        value: '1',
        label: '江南区'
      }, {
        value: '2',
        label: '柳州区'
      },],
      option2: [{
        value: '0',
        label: '一月份'
      }, {
        value: '1',
        label: '二月份'
      }, {
        value: '2',
        label: '三月份'
      }],
      value: '0',
      value2: '全部',
      value3: '全部',
      search: ''
    }
  },
  methods: {
    //在方法里配置图表
    showChart() {
      //获取Dom 元素  改元素盒子里面ID为myChart
      let myChart = echarts.init(document.getElementById('myChart'))
      //指定图表的配置项和数据
      let option = {
        color: ['#4ecbe3', '#ff7079'],
        // legend: { show:true,//图例显示 orient: 'vertical', left: 'left', data:['1','2','3'], selected: { '1' : false, '2' : false, //不想被点起作用的都设置成false } }
        legend: { show: true, right: '10%' },
        tooltip: {},
        dataset: {
          dimensions: ['product', '每月收益', '每月支出'],
          source: [
            { product: '1', '每月收益': 85000, '每月支出': 64000, },
            { product: '2', '每月收益': 170000, '每月支出': 156000, },
            { product: '3', '每月收益': 85000, '每月支出': 64000, },
            { product: '4', '每月收益': 85000, '每月支出': 64000, },
            { product: '5', '每月收益': 85000, '每月支出': 64000, },
            { product: '6', '每月收益': 85000, '每月支出': 64000, },
            { product: '7', '每月收益': 85000, '每月支出': 64000, },
            { product: '8', '每月收益': 85000, '每月支出': 64000, },
            { product: '9', '每月收益': 85000, '每月支出': 64000, },
            { product: '10', '每月收益': 85000, '每月支出': 64000, },
            { product: '11', '每月收益': 85000, '每月支出': 64000, },
            { product: '12', '每月收益': 85000, '每月支出': 64000, }
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        //Declareseveralbarseries,eachwillbemapped
        //toacolumnofdataset.sourcebydefault.
        series: [
          { type: 'bar' },
          { type: 'bar' },
        ]
      }
      //使用杠指定的配置项和书籍显示图表
      myChart.setOption(option)



      //右边图表
      let orderEchart = echarts.init(document.getElementById('orderEchart'))
      let orderoption = {
        color: ['#8674eb', '#4ecbe3', '#ff7079'],
        tooltip: {//提示框组件
          trigger: 'item'
        },
        graphic: [{ //环形图中间添加文字
          type: 'text', //通过不同top值可以设置上下显示
          left: 'center',
          top: '45%',
          style: {
            text: '133',
            textAlign: 'center',
            fill: '#606266', //文字的颜色
            width: 20,
            height: 20,
            fontSize: 24,
            fontFamily: "Microsoft YaHei"
          },
        }, { 
          type: 'text', 
          left: 'center',
          top: '52%',
          style: {
            text: '2021年7月总计',
            textAlign: 'center',
            fill: '#606266', 
            width: 20,
            height: 20,
            fontSize: 16,
            fontFamily: "Microsoft YaHei"
          },
        },
        ],

        // legend: {
        //   top: '5%',
        //   left: 'center'
        // },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            left: '0%',
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 66, name: '已完成' },
              { value: 51, name: '进行中' },
              { value: 16, name: '已退款' }
            ]
          },
        ]
      };

      // orderEchart.on('updateAxisPointer', function (event) {
      //   var xAxisInfo = event.axesInfo[0];
      //   if (xAxisInfo) {
      //     var dimension = xAxisInfo.value + 1;
      //     orderEchart.setOption({
      //       series: {
      //         id: 'pie',
      //         label: {
      //           formatter: '{b}: {@[' + dimension + ']} ({d}%)'
      //         },
      //         encode: {
      //           value: dimension,
      //           tooltip: dimension
      //         }
      //       }
      //     });
      //   }
      // });
      orderEchart.setOption(orderoption)


    }
  },
  mounted() {
    this.showChart()
  }
}
</script>

<style lang="scss">
.financial-container {
  box-sizing: border-box;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;
  .eacharbox-container {
    display: flex;
    .el-select {
      width: 100px;
    }
    .echar-title {
      font-size: 20px;
      font-weight: bold;
      .el-select {
        margin-left: 20px;
      }
    }
    .leftecharBox-container {
      padding: 20px;
      text-align: left;
      width: 990px;
      height: 400px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      border-radius: 20px;
      #myChart {
        flex: 1;
        width: 100%;
      }
    }
    .rightecharBox-container {
      flex: 1;
      padding: 20px;
      text-align: left;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      border-radius: 20px;
      .orderEchartBox {
        display: flex;
        align-items: center;
        #orderEchart {
          flex: 1;
          width: 360px;
          height: 360px;
        }
        .orderEchart-progress-box {
          width: 200px;
          height: 150px;
          margin-right: 60px;

          .progress {
            width: 180px;
            height: 60px;
            background-color: #f9f9f9;
            padding: 20px 10px 0px 10px;
            border-radius: 10px;
            p {
              color: #606266;
              font-size: 24px;
              margin-bottom: 10px;
              span {
                font-size: 14px;
              }
            }
            .done {
              .el-progress-bar {
                .el-progress-bar__inner {
                  background-color: #8674eb;
                }
              }
            }
            .doing {
              .el-progress-bar {
                .el-progress-bar__inner {
                  background-color: #4ecbe3;
                }
              }
            }
            .undone {
              .el-progress-bar {
                .el-progress-bar__inner {
                  background-color: #ff7079;
                }
              }
            }
          }
          .progress-tittle {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 16px;
              color: #606266;
            }
            .el-button {
              width: 50px;
              height: 20px;
              padding: 0;
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .finanacial-btncontainer {
    text-align: left;
    margin: 20px 0;

    .allbtn,
    .selectbtn,
    .el-input,
    .el-input__inner {
      width: 100px;
      margin-right: 20px;
      color: #ffffff;
      // background-color: $index-buttonerr-color;
    }
    .selectmountclass {
      .el-input__inner {
        background-color: $index-buttonerr-color;
      }
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        i {
          color: #ffffff;
        }
        .el-icon-search {
          color: #c0c4cc;
        }
      }
    }
    .allbtn {
      background-color: $index-buttonPor-color;
    }
    .selectbtnclass {
      .el-input__inner {
        background-color: $index-button-color;
      }
    }
    .searchinput {
      width: 200px;
      .el-input__inner {
        width: 200px;
      }
    }
  }
}
</style>