<template>
  <div>
    <el-dialog
      width="1000px"
      title="财务详细"
      :visible.sync="editfinancialVisible"
      append-to-body
      @close="editfinancialVisibleClose"
      :before-close="editfinancialVisibleClose"
      custom-class="editfinancial-dialog"
      top="10%"
    >
      <div class="editfinancial-container">
        <div class="editfinancial-gridcontainer">
          <!-- 业务编号 -->
          <div class="editfinancial-gridItem">
            <span>业务编号:</span>
            <span>{{ rowdata.business_number }}</span>
          </div>
          <!-- 公司名称 -->
          <div class="editfinancial-gridItem">
            <span>公司名称:</span>
            <span>{{ rowdata.corporate_name }}</span>
          </div>
          <!-- 业务返单日期 -->
          <div class="editfinancial-gridItem">
            <span>业务返单日期:</span>
            <span>{{ rowdata.business_return_date }}</span>
          </div>
          <!-- 业务名称 -->
          <div class="editfinancial-gridItem">
            <span>业务名称:</span>
            <span>{{ rowdata.business_name }}</span>
          </div>
          <!-- 业务片区 -->
          <div class="editfinancial-gridItem">
            <span>业务片区:</span>
            <span>{{ rowdata.business_area }}</span>
          </div>
          <!-- 业务状态 -->
          <div class="editfinancial-gridItem">
            <span>业务状态:</span>
            <span>{{ rowdata.business_status }}</span>
          </div>
          <!-- 负责人 -->
          <div class="editfinancial-gridItem">
            <span>负责人:</span>
            <span>{{ rowdata.perso_incharge }}</span>
          </div>
          <!-- 客服 -->
          <div class="editfinancial-gridItem">
            <span>客服:</span>
            <span>{{ rowdata.customer_service }}</span>
          </div>
        </div>
        <div class="editfinancial-moneycontentcontainer">
          <div class="editfinancial-biggridcontainer">
            <!-- 业务金额（元） -->
            <div class="editfinancial-gridItem">
              <span>业务金额(元):</span>
              <span>{{ rowdata.business_amount }}</span>
            </div>
            <!-- 收款情况 -->
            <div class="editfinancial-gridItem">
              <span>收款情况:</span>
              <span>{{ rowdata.payment_status }}</span>
            </div>
            <!-- 实收金额 -->
            <div class="editfinancial-gridItem">
              <span>实收金额(元):</span>
              <span>{{ rowdata.paid_in_amount }}</span>
            </div>
            <!-- 未付金额（元） -->
            <div class="editfinancial-gridItem">
              <span>未付金额(元):</span>
              <span>{{ rowdata.unpaid_amount }}</span>
            </div>
            <!-- 有效业绩 -->
            <div class="editfinancial-gridItem">
              <span>有效业绩:</span>
              <span>{{ rowdata.effective_performance }}</span>
            </div>
            <!-- 人工审核业绩 -->
            <div class="editfinancial-gridItem">
              <span>人工审核业绩:</span>
              <span>{{ rowdata.manual_review_performance }}</span>
            </div>
            <!-- 提成（元） -->
            <div class="editfinancial-gridItem">
              <span>提成(元):</span>
              <span>{{ rowdata.percentage }}</span>
            </div>
            <!-- 提成计提 -->
            <div class="editfinancial-gridItem">
              <span>提成计提:</span>
              <span>{{ rowdata.percentage_impairment }}</span>
            </div>
          </div>
          <template>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column
                prop="income"
                label="收款金额(元)"
                width="120"
              ></el-table-column>
              <el-table-column prop="collectiontype" label="收款类型"></el-table-column>
              <el-table-column prop="collectiontime" label="收款时间">
              </el-table-column>
              <el-table-column prop="collectionscreenshot" label="收款截图">
                <template slot-scope="scope">
                    <div class="flexBox">
                      <div
                        class="screenshotItem"
                        v-for="(item, index) in scope.row.collectionscreenshot"
                        :key="index"
                      >
                        <img
                          src="../../assets/shouju.png"
                          alt=""
                          height="60px"
                        />
                      </div>
                    </div>
                  </template>
              </el-table-column>
              <el-table-column prop="pay_amount" label="代支金额(元)" width="120">
              </el-table-column>
              <el-table-column prop="pay_type" label="付款类型">
              </el-table-column>
              <el-table-column prop="pay_time" label="付款时间">
              </el-table-column>
              <el-table-column prop="pay_screenshot" label="待支截图">
                <template slot-scope="scope">
                    <div class="flexBox">
                      <div
                        class="screenshotItem"
                        v-for="(item, index) in scope.row.pay_screenshot"
                        :key="index"
                      >
                        <img
                          src="../../assets/shouju.png"
                          alt=""
                          height="60px"
                        />
                      </div>
                    </div>
                  </template>
              </el-table-column>
              <el-table-column prop="balance" label="代收代支平衡" width="120">
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div class="editfinancial-approvalprocess">
          <div class="editfinancial-approvalprocess-title">审批流程:</div>
          <el-steps
            :active="3"
            class="editfinancial-steps"
            space="20%"
            align-center
          >
            <el-step
              title="天灿灿(客服)"
              icon="el-icon-upload"
              description="已提交"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="editfinancial-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天灿灿(主管)"
              icon="el-icon-upload"
              description="已确认"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="editfinancial-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天灿灿(总经理)"
              icon="el-icon-upload"
              description="已确认"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="editfinancial-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天灿灿(财务)"
              icon="el-icon-upload"
              description="待确认"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #999999;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="editfinancial-steps-img"
                />
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="editfinancial-pscontainer">
          <div class="editfinancial-approvalprocess-title">备注内容:</div>
          <div class="editfinancial-flexBox">
            <div class="editfinancial-flexItem">
              <div class="flexitemleft">
                <div class="flexitem-ava">
                  <img src="../../assets/taskavaimg.png" alt="" srcset="" />
                </div>
                <div class="flexitem-detailcontainer">
                  <div class="flexitem-detailcontainer-title">
                    <span>天灿灿</span><span>2021-07-30 15:23:58</span>
                  </div>
                  <div class="flexitem-detailcontainer-content">
                    <span>客户已通过转账2400元完成结款</span>
                  </div>
                </div>
              </div>
              <div class="flexitembtn">
                <el-button>添加图片</el-button>
              </div>
            </div>
          </div>
          <div class="editfinancial-approvalprocess-btnBox">
            <el-button icon="el-icon-circle-plus-outline" class="addpsbtn"
              >添加备注</el-button
            >
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="1" class="rejectbtn">驳回</el-button>
        <el-button @click="1" class="resBtn">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>

export default {
  data() {
    return {
      tableData: [{
        /*
        收款金额(元):income
        收款类型:collectiontype
        收款时间:collectiontime
        收款截图:collectionscreenshot
        代支金额:pay_amount
        付款类型:pay_type
        付款时间:pay_time
        代支截图:pay_screenshot
        代收代支平衡:balance
        
        */
        income: '20000(代收)',
        collectiontype: '支付宝',
        collectiontime: '2021-08-01 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        pay_amount: '600',
        pay_type: '微信',
        pay_time: '2021-08-27 16:05:05',
        pay_screenshot: ['../../assets/shouju.png'],
        balance: '400元'
      }
      ]
    }
  },
  props: {
    editfinancialVisible: {
      type: Boolean,
      default: false
    },
    rowdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    editfinancialVisibleClose() {
      console.log('内置dialog被关闭')
      this.$emit('editfinancialVisibleClose')
    },
  }
}
</script>

<style lang="scss">
.editfinancial-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 600px;
    overflow: auto;
    padding: 50px;
    .editfinancial-gridcontainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      padding-bottom: 20px;
      border-bottom: 2px solid #f2f2f2;
      .editfinancial-gridItem {
        display: flex;
      }
    }
    .editfinancial-moneycontentcontainer {
      padding: 20px 0;
      border-bottom: 2px solid #f2f2f2;
       th {
          background-color: #f2f2f2;
        }
        th,
        td {
          border: none;
          text-align: center;
        }
        .flexBox {
          display: flex;
          .screenshotItem {
            // margin-left: 10px;
            flex: 1;
            img {
              height: 30px;
            }
          }
        }
      .editfinancial-biggridcontainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
      }
    }
    .editfinancial-approvalprocess-title {
      font-size: 16px;
      margin-bottom: 30px;
    }
    .editfinancial-approvalprocess {
      padding: 20px 0;
      border-bottom: 2px solid #f2f2f2;

      .editfinancial-steps {
        .editfinancial-steps-img {
          border-radius: 50%;
        }
      }
    }
    .editfinancial-pscontainer {
      padding: 20px 0;
      .editfinancial-flexBox {
        padding: 0 20px;
        margin-bottom: 30px;
      }
      .editfinancial-flexItem {
        display: flex;
        justify-content: space-between;
        .flexitemleft {
          display: flex;
          .flexitem-ava {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
          .flexitem-detailcontainer {
            margin-left: 20px;
          }
        }
        .flexitembtn {
          .el-button {
            color: #ffffff;
            background-color: #8674eb;
          }
        }
      }
      .editfinancial-approvalprocess-btnBox {
        text-align: center;
      }
      .addpsbtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .rejectbtn {
        background-color: $index-buttonerr-color;
        color: #ffffff;
      }
      .resBtn {
        background-color: $index-button-color;
        color: #ffffff;
      }
    }
  }
}
</style>