<template>
  <div class="pagination-container">
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage3"
      layout="prev, pager, next, jumper"
      :total="1000"
    >
    </el-pagination> -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pagenum"
      :page-sizes="[8, 12, 16, 20]"
      :page-size="queryInfo.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    getcustomerList: {
      type: Function
    },
    total: {
      type: Number,
      default: 0
    },
    getemployeeList: {
      type: Function
    },
    getexamineList: {
      type: Function
    }

  },
  data() {
    return {
      queryInfo: {
        // 查询的条件
        query: '',
        // 当前的页数，即页码
        pagenum: 1,
        // 每页显示的数据条数
        limit: 20
      },

    }
  },
  methods: {
    handleCurrentChange(current) {
      this.$emit('pagecurrent', current)
      if (window.sessionStorage.getItem('activePath') == "/businessManagement")
        this.getcustomerList()
      if (window.sessionStorage.getItem('activePath') == "/employeemanagement")
        this.getemployeeList()
      if (window.sessionStorage.getItem('activePath') == "/businessexamine")
        this.getexamineList()
      // /businessexamine
      console.log(current);
    },
    handleSizeChange(size) {
      // this.queryInfo.pagesize = size

      this.$emit('pagesize', size)
      if (window.sessionStorage.getItem('activePath') == "/businessManagement")
        this.getcustomerList()
      if (window.sessionStorage.getItem('activePath') == "/employeemanagement")
        this.getemployeeList()
      if (window.sessionStorage.getItem('activePath') == "/businessexamine")
        this.getexamineList()
      console.log(size);
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  margin-top: 30px;
  height: 40px;
  .el-pagination {
    height: 100%;
    box-sizing: border-box;
    color: #666666;
    .el-pager li {
      padding: 0 25px;
      font-size: 16px !important;
      height: 100%;
      line-height: 40px;
    }
    .el-pager li.active {
      color: #409eff;
    }
    .btn-prev,
    .btn-next {
      padding: 0 30px;
      height: 40px;
      text-align: center;
    }
    .btn-prev {
      border-radius: 10px 0 0 10px;
    }
    .btn-next {
      border-radius: 0px 10px 10px 0px;
    }
    .el-pagination__jump {
      .el-input {
        margin: 0 20px;
        font-size: 16px;
      }
    }
    button,
    span:not([class*="suffix"]) {
      height: 40px;
      line-height: 40px;
      font-size: 16px !important;
    }
  }
  .el-icon-arrow-left:before {
    content: "上一页";
    font-size: 16px;
  }
  .el-icon-arrow-right:before {
    content: "下一页";
    font-size: 16px;
  }
}
</style>