<template>
  <!-- 财务管理表格 -->
  <div class="financialtable-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      class="financialtable"
      stripe
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="60"
        fixed
      >
      </el-table-column>

      <!-- 公司名称 -->
      <el-table-column
        prop="corporate_name"
        label="公司名称"
        width="110"
        align="center"
      >
      </el-table-column>

      <!-- 业务名称 -->
      <el-table-column
        prop="business_name"
        label="业务名称"
        width="90"
        align="center"
        min-width="110"
      >
      </el-table-column>

      <!-- 业务金额(元) -->
      <el-table-column
        prop="business_amount"
        label="业务金额(元)"
        width="120"
        align="center"
      >
      </el-table-column>

      <!-- 付款类型 -->
      <el-table-column
        prop="payment_type"
        label="付款类型"
        width="90"
        align="center"
      >
      </el-table-column>

      <!-- 实收金额 -->
      <el-table-column
        prop="paid_in_amount"
        label="实收金额"
        width="90"
        align="center"
      >
      </el-table-column>

      <!-- 付款时间 -->
      <el-table-column
        prop="payment_time"
        label="付款时间"
        width="160"
        align="center"
      >
      </el-table-column>

      <!-- 收据截图 -->
      <el-table-column
        prop="screenshot_of_receipt"
        label="收据截图"
        align="center"
        min-width="160"
      >
        <template slot-scope="{ row }">
          <img
            src="../../assets/shouju.png"
            alt=""
            v-for="(item, index) in row.screenshot_of_receipt"
            :key="index"
          />
          <!-- <span v-for="item in row.available_roles" :key='item' class="available_rolesspan">{{item}}</span> -->
        </template>
      </el-table-column>

      <!-- 付款状态 -->
      <el-table-column
        prop="payment_status"
        label="付款状态"
        width="90"
        align="center"
      >
      </el-table-column>

      <!-- 代收支平衡(元) -->
      <el-table-column
        prop="expenditure"
        label="代收支平衡(元)"
        width="100"
        align="center"
      >
      </el-table-column>

      <!-- 业务片区 -->
      <el-table-column
        prop="business_area"
        label="业务片区"
        width="90"
        align="center"
      >
      </el-table-column>

      <!-- 客服 -->
      <el-table-column
        prop="stomer_service"
        label="客服"
        width="80"
        align="center"
      >
      </el-table-column>

      <!-- 负责人 -->
      <el-table-column
        prop="perso_incharge"
        label="负责人"
        width="80"
        align="center"
      >
      </el-table-column>

      <!-- 业务状态 -->
      <el-table-column
        prop="business_status"
        label="业务状态"
        width="200"
        align="center"
      >
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button @click="open" type="button" class="confirmbtn"
            >确认</el-button
          >
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >详细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <editmenu-com
      :editmenuVisible="editmenuVisible"
      :rowdata="rowdata"
      @editmenuVisibleClose='editmenuVisibleClose'
    ></editmenu-com> -->
    <editfinancial
      :editfinancialVisible="editfinancialVisible"
      @editfinancialVisibleClose="editfinancialVisibleClose"
      :rowdata="rowdata"
    ></editfinancial>
  </div>
</template>


<script>
// import editmenuCom from './editmenuCom.vue'
import editfinancial from './editfinancialCom.vue'


export default {
  components: {
    // editmenuCom
    editfinancial
  },
  data() {
    return {
      editfinancialVisible: false,
      rowdata: {},
      /**
       * 公司名称:corporate_name
       * 业务名称:business_name
       * 业务金额:business_amount
       * 付款类型:payment_type
       * 实收金额:paid_in_amount
       * 付款时间:payment_time
       * 未付金额:unpaid_amount
       * 收据截图:screenshot_of_receipt
       * 付款状态:payment_status
       * 代收支平衡(元):expenditure
       * 业务片区:business_area
       * 客服:customer_service
       * 负责人:perso_incharge
       * 业务状态:business_status
       * 业务返单日期:business_return_date
       * 业务编号:business_number
       * 有效业绩:effective_performance
       * 人工审核业绩:manual_review_performance
       * 提成:percentage
       * 提成计提:percentage_impairment
       */
      //   最多7条数据可以展示在页面上  所以一页就是7条
      tableData: [{
        corporate_name: '广西天灿人力资源有限公司',
        business_name: '套餐B',
        business_amount: '2400',
        payment_type: '支付宝',
        paid_in_amount: '400',
        payment_time: '2021-08-05 15:30:05',
        unpaid_amount: '2000',
        screenshot_of_receipt: [1, 1, 1],
        payment_status: '已收定金未收尾款',
        expenditure: '600',
        business_area: '青秀区',
        customer_service: '天小灿',
        perso_incharge: '天灿灿',
        business_status: '未完成(未收尾款/业务进行中)',
        business_return_date: '2021-8-26',
        business_number: '202108271',
        effective_performance: '400元',
        manual_review_performance: '333元',
        percentage: '100元',
        percentage_impairment: '未完成'
      },
      {
        corporate_name: '广西天灿人力资源有限公司',
        business_name: '套餐B',
        business_amount: '2400',
        payment_type: '支付宝',
        paid_in_amount: '400',
        payment_time: '2021-08-05 15:30:05',
        unpaid_amount: '2000',
        screenshot_of_receipt: [1, 1, 1],
        payment_status: '已收定金未收尾款',
        expenditure: '600',
        business_area: '青秀区',
        customer_service: '天小灿',
        perso_incharge: '天灿灿',
        business_status: '未完成(未收尾款/业务进行中)',
        business_return_date: '2021-8-26',
        business_number: '202108271',
        effective_performance: '400元',
        manual_review_performance: '333元',
        percentage: '100元',
        percentage_impairment: '未完成'
      }
      ],
    }
  },
  props: {

  },
  methods: {
    handleClick(row) {
      console.log(row);
      this.rowdata = row
      this.editfinancialVisible = true
    },
    editfinancialVisibleClose() {
      this.editfinancialVisible = false
    },
    filterTag(value, row) {
      return row.state === value;
    },
    open() {
      this.$message({
        message: '您已成功确认',
        type: 'success'
      });
    }
  }
}
</script>



<style lang="scss">
.financialtable-container {
  height: 550px;
  overflow: hidden;
  .available_rolesspan {
    margin-left: 5px;
  }
  .available_rolesspan:first-child {
    margin-left: 0px;
  }
  .financialtable {
    thead {
      th {
        color: #ffffff;
        background-color: $index-buttonPor-color;
      }
    }

    .seebtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
    .confirmbtn {
      color: #ffffff;
      background-color: $index-button-color;
    }
    .withdrawbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
  }
}
</style>